@use "sass:math";

$primary: #ED8A00;
$widescreen-enabled: false;
$fullhd-enabled: false;
$section-padding: 0 1.5rem; // default 3rem 1.5rem

$calendar-body-padding: 0;
$calendar-header-padding: 0;
$calendar-header-background-color: transparent;

// @use also makes sure the resolver for url() etc works on the package's path,
// so it's essential when a dependency imports images or fonts.
// @import is deprecated, but I don't know how to import the variables
// otherwise, something something @include...
// Update to @use once we figure it out.

@use 'leaflet';

// $fa-font-path: "@fortawesome/fontawesome-free/webfonts";
@use '@fortawesome/fontawesome-free/scss/fontawesome';
@use '@fortawesome/fontawesome-free/scss/solid';

// keep pre-0.8 sized buttons + inputs
$control-border-width: 1px;
$button-border-width: $control-border-width;
$control-height: 2.25em;
$control-padding-vertical: calc(0.375em - #{$control-border-width});
$control-padding-horizontal: calc(0.625em - #{$control-border-width});
$button-padding-vertical: calc(0.375em - #{$button-border-width});
$button-padding-horizontal: 0.75em;

@import 'bulma/sass/utilities/_all';
@import 'bulma/sass/base/_all';
@import 'bulma/sass/helpers/_all';
@import 'bulma/sass/layout/hero';
@import 'bulma/sass/layout/section';
@import 'bulma/sass/form/_all';
@import 'bulma/sass/elements/container';
@import 'bulma/sass/elements/button';
@import 'bulma/sass/elements/title';
@import 'bulma/sass/elements/tag';
@import 'bulma/sass/elements/box';
@import 'bulma/sass/components/message';

@import 'bulma-calendar';
@import 'bulma-tooltip';
@import 'bulma-checkradio';


//.optional-info {
//    font-size: 80%;
//    opacity: 0.8;
//}

//html {
//    background-color: $white-bis;
//}
//
//.section {
//    background-color: $white;
//}

.header .subtitle {
    white-space: pre-line;
}


.survey-actions {
    background-color: $white-bis;

    z-index: 1500;
    position: fixed;
    width: 100%;

    //position: sticky;
    padding-top: 1rem;
    padding-bottom: 1rem;
    bottom: 0;
    //border-top: 1px solid $white-ter;
}

    .rating {
        font-size: 1.5em;
        font-weight: bold;
        margin-left: 1.4rem;
    }

    .survey-nav-title {
        display: none;
    }

    @media screen and (max-width: ($tablet - 1)), print {
        .button.survey-nav .icon {
            margin-left:  calc(-0.375em - 1px) !important;
            margin-right: calc(-0.375em - 1px) !important;
        }
    }
    @media screen and (min-width: $tablet), print {
        .survey-nav-title {
            display: inline-block;
            width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .survey-actions .button > div:first-child {
        position: absolute;
        color: $text;
    }


.survey-section {
    padding-top: 2em;
    padding-bottom: 6em;
}

    .survey-section:not(.is-selected) {
        display: none;
    }


.survey-section > .field {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

    .survey-section > .field.is-danger {
        padding: 0.5em;
        margin: -0.5em;
        background-color: #fcc;
        border-radius: 5px;
    }

    .question-identifier {
        float: left;
        margin-right: 1em;
        font-weight: normal;
    }

    .required-star {
        color: red;
    }


input[type="date"].input:invalid {
    border-color: $danger;
}

// .date-input {
//     width: 12.5em; // aligned with time-input (3.5em * 3 + 1em * 2)
//     position: relative;
// }

//     .date-input .box {
//         z-index: 10;
//         top: $control-height;
//         padding: 0.5rem;
//     }

//     .calendar .calendar-nav {
//         padding: 0.2em 0 0 0;
//     }

//     .calendar .calendar-nav,
//     .calendar .calendar-nav .calendar-nav-left .button,
//     .calendar .calendar-nav .calendar-nav-right .button,
//     .calendar .calendar-nav .calendar-nav-left .button:hover,
//     .calendar .calendar-nav .calendar-nav-right .button:hover {
//         color: $text;
//     }

//     .calendar .button.is-link {
//         background-color: transparent;
//     }

//     .calendar .calendar-nav .calendar-nav-left .button:hover,
//     .calendar .calendar-nav .calendar-nav-right .button:hover {
//         opacity: 0.6;
//     }

//     .calendar .calendar-date .date-item.is-today {
//         color: inherit;
//     }

.time-input {
    width: 3.5em;
    //margin-right: 0.5em;
}

    .time-delimiter {
        vertical-align: sub;
        display: inline-block;
        width: 1em;
        text-align: center;
    }


// .datetime-input .date-input {
//     margin-right: 1em;
//     margin-bottom: 0.75rem !important;
//     display: flex;
// }

//     @media screen and (min-width: 450px) {
//         .datetime-input .date-input {
//             margin-bottom: 0;
//             display: inline-flex;
//         }
//     }


.checkradio-list .field {
    padding-top: 0.375em;
    margin-bottom: 0;
}

.is-checkradio[type=radio] + label,
.is-checkradio[type=checkbox] + label {
    margin: 0;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0.4rem;
}


.upload .button {
    position: relative;
}

    .upload .button input {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .upload .preview {
        margin-top: 0.375em;
    }

        .upload .preview img {
            display: block;
            margin: auto;
            max-height: 300px;
        }


.geolocation-select {
    height: 300px;
}

    $geolocation-marker-color: $cyan;
    $geolocation-marker-width: 20px;
    $geolocation-marker-halfwidth: math.div($geolocation-marker-width, 2);

    .geolocation-marker {
        border-left: $geolocation-marker-halfwidth solid transparent;
        border-right: $geolocation-marker-halfwidth solid transparent;
        border-top: $geolocation-marker-width solid $geolocation-marker-color;
        width: $geolocation-marker-width !important;
        height: $geolocation-marker-width !important;
        margin-top: -$geolocation-marker-width !important;
        margin-left: -$geolocation-marker-halfwidth !important;
        position: relative;
    }

    .geolocation-marker::before {
        content: '';
        display: block;
        width: $geolocation-marker-width;
        height: $geolocation-marker-width;
        margin-top: -($geolocation-marker-width * 1.6);
        margin-left: -$geolocation-marker-halfwidth;
        position: absolute;
        background-color: $geolocation-marker-color;
        border-radius: $geolocation-marker-halfwidth;
    }

    .geolocation-marker::after {
        content: '';
        display: block;
        width: $geolocation-marker-halfwidth;
        height: $geolocation-marker-halfwidth;
        margin-top: -($geolocation-marker-width * 1.35);
        margin-left: -(math.div($geolocation-marker-halfwidth, 2));
        position: absolute;
        background-color: $white;
        border-radius: $geolocation-marker-halfwidth;
    }


.suggestion {
    margin-top: 1em;
}
